<script lang="ts" setup>
import type { VideoHTMLAttributes } from 'vue'
import type { HeroBlockAsset } from '~/types/storyblok/hero-block-v2'

defineProps<{
  asset: HeroBlockAsset
  dataParentPosition: number
}>()

const videoProps = computed<VideoHTMLAttributes>(() => ({
  autoplay: true,
  muted: true,
  loop: true,
  playsinline: true,
}))

function isVideoAsset(url?: string) {
  return url && url.endsWith('.mp4')
}
</script>

<template>
  <!-- Video:Vimeo -->
  <VideoEmbed
    v-if="asset.vimeoVideoId"
    :style="{ pointerEvents: 'none' }"
    source="vimeo"
    :keyboard="asset.enableVideoControls"
    :autoplay="!asset.enableVideoControls"
    :mute="!asset.enableVideoControls"
    loop
    :video-id="asset.vimeoVideoId"
    :thumbnail="asset.url"
    :video-size="asset.videoAspectRatio"
  />

  <!-- Video:Native -->
  <AspectRatio
    v-else-if="isVideoAsset(asset.url)"
    :ratio="asset.videoAspectRatio"
  >
    <Video
      :video-props="videoProps"
      grow="both"
    >
      <source
        :src="asset.url"
        type="video/mp4"
      >
    </Video>
  </AspectRatio>

  <!-- Image -->
  <ImageResponsive
    v-else
    provider="storyblok"
    :src="asset.url || ''"
    :alt="asset.alt"
    :large-asset="asset.url"
    :breakpoint="0"
    :lazy="dataParentPosition !== 0"
    object-fit="fill"
  />
</template>
