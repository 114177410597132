<script lang="ts" setup>
const props = defineProps<{ ratio?: [number, number] }>()

const paddingTop = computed(() => {
  if (!props.ratio)
    return '0%'
  return `${(props.ratio[1] / props.ratio[0]) * 100}%`
})
</script>

<template>
  <div
    v-if="ratio"
    class="aspect-ratio-wrapper"
  >
    <div class="aspect-ratio">
      <slot />
    </div>
  </div>
  <slot v-else />
</template>

<style lang="scss">
.aspect-ratio-wrapper {
  height: 0;
  padding-top: v-bind(paddingTop);
  position: relative;
}
.aspect-ratio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
